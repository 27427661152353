import { Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, DatePicker, Input, Select, Space } from "antd";
import { Button } from "antd";
import { useForm } from "react-hook-form";
import "./donationForm.scss";
import { addDonations, allDonationsByCyleId } from "../../../apis/DonationApi";
import { allCycles } from "../../../apis/CycleApi";
import { getAllDonorList } from "../../../apis/DonorApi";
const { Option } = Select;

const DonationForm = (props) => {
  const moment = require("moment");
  const [donorList, setDonorList] = useState(null);
  const [cycleList, setCycleList] = useState(null);

  const [donations, setDonations] = useState([]);
  const [isEdit, setIsEdit] = useState(props.editMode);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, type, heading, message) => {
    notification[type]({
      message: heading,
      description: message,
      placement,
    });
  };

  useEffect(() => {
    setIsEdit(props.editMode);
  }, [props]);

  const {
    formState: { errors },
    reset,
  } = useForm();

  const [form] = Form.useForm();

  const fetchDonors = () => {
    getAllDonorList().then((res) => {
      if (res.status === 200) {
        setDonorList(res.data);
      }
    });
  };

  const fetchCycles = () => {
    allCycles().then((res) => {
      if (res.status === 200) {
        let cycleList = [];
        res.data.forEach((cycle) => {
          if (cycle.status !== "COMPLETED") {
            cycleList.push(cycle);
          }
        });
        setCycleList(cycleList);
      }
    });
  };

  const fetchData = () => {
    allDonationsByCyleId(props.id).then((res) => {
      if (res.status === 200) {
        setDonations(res.data);
      }
    });
  };

  const onEditFunction = () => {
    if (props?.editorValues && props.editMode) {
      var date = moment(props?.editorValues.creationDate);
      form.setFieldsValue({
        cycle: props.editorValues?.cycleid.id,
        donor: props.editorValues?.donorId,
        amount: props.editorValues?.amount,
        creationDate: date,
        DonationType: props.editorValues?.donationType,
        crNumber: props.editorValues?.crNumber,
        paymentMethod: props.editorValues?.paymentMethod,
      });
    } else {
      form.resetFields();
      reset();
    }
  };

  useEffect(() => {
    fetchDonors();
    fetchCycles();
    fetchData();
    onEditFunction();
  }, [props?.editorValues, props.editMode]);

  const onSubmit = async (data) => {
    if (isEdit) {
      let dataForApi = {
        id: props?.editorValues?.id ? props?.editorValues?.id : "null",
        donorId: { id: data.donor },
        donationType: data.DonationType,
        amount: data.amount,
        crNumber: data.crNumber,

        creationDate: new Date(data.creationDate.format("YYYY/MM/DD")),
        paymentMethod: data.paymentMethod,
        cycleId: { id: data.cycle },
      };
      await addDonations(dataForApi).then((res) => {
        if (res.status === 200) {
          const heading = "Donation Edited Successfully !";
          const message = "Donation has been edited successfully !";
          openNotification("bottomRight", "success", heading, message);
          setIsEdit(false);
          props.load();
          reset();
          props.hide();
        }
      });
    } else {
      let dataForApi = {
        id: null,
        donorId: { id: data.donor },
        donationType: data.DonationType,
        amount: data.amount,
        creationDate: new Date(data.creationDate.format("YYYY/MM/DD")),
        crNumber: 0,
        paymentMethod: data.paymentMethod,
        cycleId: { id: data.cycle },
      };
      await addDonations(dataForApi).then((res) => {
        if (res.status === 200) {
          const heading = "Donation Added Successfully !";
          const message = "Donation has been added successfully !";
          openNotification("bottomRight", "success", heading, message);
          props.load();
          reset();
          props.hide();
        }
      });
    }
    form.resetFields();
  };

  return (
    <div>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onSubmit}>
        <Col span={20}>
          <Form.Item
            name="cycle"
            label="Cycle"
            rules={[
              {
                required: true,
                message: "Please select Cycle",
              },
            ]}
          >
            <Select
              placeholder="Enter Cycle"
              showSearch
              optionFilterProp="children"
              filterOption={true}
              allowClear
            >
              {cycleList?.map((cycle) => (
                <Option value={cycle.id}>{cycle.cycleName}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            name="donor"
            label="Donor"
            rules={[
              {
                required: true,
                message: "Please select your name",
              },
            ]}
          >
            <Select
              placeholder="Enter Donor Name"
              showSearch
              optionFilterProp="children"
              filterOption={true}
              allowClear
            >
              {donorList?.map((donor) => (
                <Option value={donor.id}>{donor.userId.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            name="creationDate"
            label="Creation Date"
            rules={[
              {
                required: true,
                message: "Please choose Date",
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            name="DonationType"
            label="Donation Type"
            rules={[
              {
                required: true,
                message: "Please select donation type",
              },
            ]}
          >
            <Select placeholder="Enter Donation Type">
              <Option value="Sadqa">Sadqa</Option>
              <Option value="Zakat">Zakat</Option>
              <Option value="Open">Open</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "please enter Amount",
              },
            ]}
          >
            <Input placeholder="Enter Amount" />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            name="paymentMethod"
            label="Payment Method"
            rules={[
              {
                required: true,
                message: "Please select Payment Method",
              },
            ]}
          >
            <Select placeholder="Enter Payment Method">
              <Option value="Cheque">Cheque</Option>
              <Option value="Cash">Cash</Option>
              <Option value="Online">Online</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Space>
                <Button type="primary" danger onClick={props.hide}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Col>
      </Form>
    </div>
  );
};

export default DonationForm;
